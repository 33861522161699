var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.$store.state.fastPermissoes.administrador == 'S' || _vm.$store.state.fastPermissoes.tesouraria == 'S') &&
      _vm.$store.state.fastPermissoes.ativo == 'S' &&
      _vm.$store.state.fastPlataformaModulos.modulo_secretaria
  )?_c('div',[_c('div',{staticClass:"fd-app-wrap"},[_c('div',{staticClass:"container"},[_c('nav',[_c('ul',{staticClass:"fd-app-breadcrumb"},[_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"/minhas-plataformas"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/minhas-plataformas')}}},[_vm._v("Minhas Plataformas")])]),_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma)}}},[_vm._v("Plataforma "+_vm._s(_vm.$store.state.fastPlataforma.nome_plataforma))])]),_c('li',{staticClass:"fd-app-breadcrumb-item"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/tesouraria')}}},[_vm._v("Modo tesouraria")])]),_vm._m(0)])])]),_c('div',{staticClass:"fd-mode-adm-content mt-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"},[_c('section',{staticClass:"fd-app-welcome text-left"},[_vm._m(1),_c('img',{staticStyle:{"max-height":"35px"},attrs:{"src":require('@/assets/images/form-effect-01.png')}})])])])]),_c('section'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"mt-5 mb-5"},[_c('a',{staticClass:"btn btn-red-hollow",attrs:{"id":"btn_voltar_modo_admin","href":'/plataforma/' + _vm.$route.params.id_plataforma + '/tesouraria'},on:{"click":function($event){$event.preventDefault();return _vm.$router.push('/plataforma/' + _vm.$route.params.id_plataforma + '/tesouraria')}}},[_c('b-icon-arrow-return-left'),_vm._v(" Voltar ")],1)])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"fd-app-breadcrumb-item active"},[_c('a',{staticClass:"aluno_font_color",attrs:{"href":"#"}},[_vm._v("Console gerencianet")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"aluno_font_color"},[_vm._v(" Console "),_c('span',[_vm._v("GerenciaNet")])])
}]

export { render, staticRenderFns }